<template>
    <base-panel panel-class="new-project-modal"
                ref="panel">
        <div class="project-form">
            <router-link :to="{ name: 'projects.index' }"
                         class="close">
                <img src="/images/icons/close-icon.svg"
                     alt="close">
            </router-link>
            <h1>
                {{ ucf$t("projects.new") }}<span class="dot">.</span>
            </h1>
            <p class="sub-title">
                {{ ucf$t("projects.new_description") }}
            </p>
            <form @submit.prevent="submitForm">
                <input class="big"
                       v-model="project.name"
                       :placeholder="ucf$t('projects.give_name')"
                       autofocus
                       name="name"
                       ref="name">
                <button type="submit"
                        class="big primary">
                    {{ ucf$t("projects.save") }}
                </button>
            </form>
        </div>
        <div class="project-sidebar">
            <div class="sidebar-item">
                <img src="/images/revit-image.svg"
                     :alt="ucf$t('projects.revit_sync_title')">
                <h2>
                    {{ ucf$t("projects.revit_sync_title") }}
                </h2>
                <p>
                    {{ ucf$t("projects.revit_sync_description") }}
                </p>
                <button>
                    {{ ucf$t("projects.revit_sync_button") }}
                </button>
            </div>
            <div class="sidebar-item">
                <img src="/images/webshop-image.svg"
                     :alt="ucf$t('projects.webshop_add_title')">

                <h2>
                    {{ ucf$t("projects.webshop_add_title") }}
                </h2>
                <p>
                    {{ ucf$t("projects.webshop_add_description") }}
                </p>
            </div>
        </div>
    </base-panel>
</template>

<script>
    import BasePanel from '../components/BasePanel';

    export default {
        name: 'ProjectCreate',
        components: { BasePanel },
        data() {
            return {
                project: {
                    name: '',
                },
            };
        },
        methods: {
            submitForm() {
                const data = {
                    name: this.project.name,
                };

                this.$http.post('projects/create', data)
                    .then(() => {
                        this.$router.replace({ name: 'projects.index' });
                    })
                    .catch((error) => {
                        this.handleErrors(error.response);
                    });
            },
        },
        mounted() {
            this.$refs.panel.open();
        },
        beforeRouteLeave(to, from, next) {
            this.$refs.panel.close();
            return next();
        },
    };
</script>
