import { roles } from "../../configuration/variables";
import SettingGeneral from '../../views/SettingGeneral';
import SettingIndex from '../../views/SettingIndex';
import SettingStaff from '../../views/SettingStaff';
import SettingStaffCreate from '../../views/SettingStaffCreate';
import SettingStaffEdit from '../../views/SettingStaffEdit';
import auth from '../middleware/_auth';
import role from '../middleware/_role';

export default [
    // Settings routes
    {
        name: 'settings.index',
        path: '/settings',
        redirect: '/settings/general',
        component: SettingIndex,
        children: [
            {
                name: 'settings.general',
                path: 'general',
                component: SettingGeneral,
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN, true)],
                },
            },
            {
                name: 'settings.staff',
                path: 'staff',
                component: SettingStaff,
                children: [
                    {
                        name: 'settings.staff.create',
                        path: 'create',
                        component: SettingStaffCreate,
                        meta: {
                            middleware: [auth, role(roles.GLOBAL_ADMIN, true)],
                        },
                    },
                    {
                        name: 'settings.staff.edit',
                        path: ':staffUser/edit',
                        component: SettingStaffEdit,
                        meta: {
                            middleware: [auth, role(roles.GLOBAL_ADMIN, true), role(roles.OU_USER_MANAGEMENT)],
                        },
                    },
                ],
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN, true)],
                },
            },
        ],
        meta: {
            middleware: [auth, role(roles.GLOBAL_ADMIN, true)],
        },
    },
];
