<template>
    <div id="app">
        <nav-bar v-if="$auth.isAuthenticated()" />
        <div v-if="$auth.isAuthenticated()"
             class="content">
            <router-view />
        </div>
        <router-view v-else />
    </div>
</template>

<script>
    export default {
        name: 'Base',
    };
</script>
