<template>
    <transition name="fade">
        <div @click.self.exact="$emit('overlay-clicked')"
             class="overlay"
             v-show="opened">
            <div :class="panelClass"
                 class="panel">
                <slot />
            </div>
        </div>
    </transition>
</template>

<script>
    import ClickOutside from 'vue-click-outside';

    export default {
        name: 'BasePanel',
        directives: { ClickOutside },
        props: {
            panelClass: {
                type: String,
                required: false,
                default() {
                    return '';
                },
            },
        },
        data() {
            return {
                opened: false,
            };
        },
        methods: {
            remount() {
                document.body.appendChild(this.$el);
            },
            open() {
                this.opened = true;
                document.body.style.overflow = 'hidden';
            },
            close() {
                this.opened = false;
                document.body.style.overflow = null;
            },
        },
        mounted() {
            document.body.appendChild(this.$el);
        },
        beforeDestroy() {
            this.$el.remove();
        },
    };
</script>

<style lang="scss">
    .overlay {
        display          : block;
        position         : fixed;
        top              : 0;
        left             : 0;
        height           : 100vh;
        width            : 100vw;
        background-color : rgba(0, 0, 0, .16);
        z-index          : 1;
    }
</style>
