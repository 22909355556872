<template>
    <div class="notifications-container">
        <div class="header">
            <h1>{{ ucf$t("notifications.notifications") }}</h1>
            <router-link :to="{ name: 'notifications.create', query: $route.query }"
                         class="btn primary"
                         v-auth.global-admin>
                {{ ucf$t("notifications.new") }}
            </router-link>
        </div>
        <div class="filters">
            <div class="filter"
                 v-auth.global-admin>
                <multiselect :close-on-select="false"
                             :multiple="true"
                             :options="customers"
                             :placeholder="ucf$t('notifications.customer')"
                             @close="useFilter"
                             class="w-1/4 mr-4"
                             deselect-label=""
                             id="customer"
                             label="name"
                             select-label=""
                             selected-label=""
                             track-by="id"
                             v-model="filterCustomers" />
            </div>
            <div class="filter">
                <multiselect :close-on-select="false"
                             :multiple="true"
                             :options="projects"
                             :placeholder="ucf$t('notifications.project')"
                             @close="useFilter"
                             class="w-1/4 mr-4"
                             deselect-label=""
                             id="project"
                             label="name"
                             select-label=""
                             selected-label=""
                             track-by="id"
                             v-model="filterProjects" />
            </div>
        </div>
        <div class="table">
            <transition-group name="list">
                <router-link :class="{'unread' : ! notification.isRead}"
                             :key="notification.id"
                             :to="{ name: 'projects.show', params: { projectId: notification.project.id } }"
                             class="row"
                             v-for="notification in notifications">
                    <div class="column">
                        <span>
                            {{ ucfirst(dateFormat(notification.createdAt, $t("global.hour"))) }}
                        </span>
                    </div>
                    <div class="column">
                        <div v-html="ucfirst(notification.text)"
                             v-if="notification.notificationTypeId === notificationTypes.CUSTOM" />
                        <div v-html="ucf$t('notifications.shared_project', { project: notification.project.name })"
                             v-if="notification.notificationTypeId === notificationTypes.PROJECT_SHARED" />
                    </div>
                </router-link>
            </transition-group>
            <infinite-loading :distance="400"
                              @distance="1"
                              @infinite="infiniteHandler"
                              ref="infiniteLoading"
                              spinner="spiral">
                <div class="no-results"
                     slot="no-more">
                    {{ ucf$t("notifications.no_more") }}
                </div>
            </infinite-loading>
        </div>
        <router-view />
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect';

    export default {
        name: 'NotificationIndex',
        components: { Multiselect },
        data() {
            return {
                notifications: [],
                customers: [],
                projects: [],
                filterCustomers: [],
                filterProjects: [],
                page: 1,
                maxPage: 2,
            };
        },
        methods: {
            infiniteHandler(state) {
                let url = `notifications?page=${this.page}`;

                if (this.filterProjects.length > 0) {
                    url += `&projects=${this.filterProjects.map(project => project.id)}`;
                }

                if (this.filterCustomers.length > 0) {
                    url += `&customers=${this.filterCustomers.map(customer => customer.id)}`;
                }
                if (this.page > this.maxPage) {
                    state.complete();
                }

                this.$http.get(url, {
                    projects: this.filterProjects.map(project => project.id),
                    customers: this.filterCustomers.map(customer => customer.id),
                })
                    .then((response) => {
                        response.data.data.forEach((notification) => {
                            this.notifications.push(notification);
                        });
                        this.maxPage = response.data.meta.last_page;
                        state.loaded();
                    });

                if (this.page === 1) {
                    this.$http.post('notifications/unread');
                }

                this.page = this.page + 1;
            },
            useFilter() {
                this.notifications = [];
                this.page = 1;
                this.maxPage = 2;
                setTimeout(() => {
                    this.$refs.infiniteLoading.stateChanger.reset();
                }, 500);
            },
        },
    };
</script>
