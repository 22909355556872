<template>
    <base-panel @overlay-clicked="close"
                panel-class="modal project-reopen-modal small"
                ref="panel">
        <div class="header">
            {{ ucf$t("projects.transfer") }}
        </div>
        <div class="body">
            <p>
                {{ ucf$t("projects.transfer_description") }}
            </p>
            <button @click="confirm"
                    class="primary">
                {{ ucf$t("global.yes") }}
            </button>
            <button @click="close"
                    class="secondary"
                    type="button">
                {{ ucf$t("global.no") }}
            </button>
        </div>
    </base-panel>
</template>

<script>
    import moment from 'moment';
    import BasePanel from '../components/BasePanel';

    export default {
        name: 'ProjectShareSharedWithTransfer',
        components: { BasePanel },
        methods: {
            close() {
                this.$router.replace({
                    name: 'projects.share.shared-with',
                    params: { projectId: this.project.id },
                    query: this.$route.query,
                });
            },
            confirm() {
                this.$http
                    .post(`/projects/${this.$route.params.project}/share/${this.$route.params.user}/transfer`, this.$route.params)
                    .then(() => {
                        this.$router.push({
                            name: 'projects.share.shared-with',
                            params: { projectId: this.project.id },
                            query: { t: moment().format('HHmmssSSS') },
                        });
                    })
                    .catch((error) => {
                        this.handleErrors(error.response);
                    });
            },
        },
        mounted() {
            this.$refs.panel.open();
        },
        beforeRouteLeave(to, from, next) {
            this.$refs.panel.close();
            return next();
        },
    };
</script>
