<template>
    <transition>
        <div class="navbar-container">
            <div class="logo">
                <img class="big"
                     src="/images/nav-logo.svg"
                     title="logo">
                <img class="small"
                     src="/images/nav-logo-small.svg"
                     title="logo">
            </div>
            <router-link :to="{ name: 'projects.index' }"
                         class="navbar-item">
                <img src="/images/icons/company-icon.svg"
                     title="company-icon">
                <span>
                    {{ ucf$t("projects.project") }}
                </span>
            </router-link>
            <router-link :to="{ name: 'customers.index' }"
                         class="navbar-item"
                         v-auth.global-admin>
                <img src="/images/icons/customers-icon.svg"
                     title="company-icon">
                <span>
                    {{ ucf$t("customers.customers") }}
                </span>
            </router-link>
            <router-link :to="{ name: 'notifications.index' }"
                         class="navbar-item">
                <img src="/images/icons/notifications-icon.svg"
                     title="notifications-icon">
                <span>Notificaties</span>
                <transition name="fade">
                    <span class="notifications"
                          v-if="notificationAmount > 0">{{ notificationAmount }}</span>
                </transition>
            </router-link>
            <router-link :to="{ name: 'settings.index' }"
                         class="navbar-item"
                         v-auth.global-admin:invert>
                <img src="/images/icons/settings-icon.svg"
                     title="settings-icon">
                <span>Instellingen</span>
            </router-link>
            <router-link :to="{ name: 'frames.index' }"
                         class="navbar-item"
                         v-auth.global-admin>
                <span>
                    {{ $t("Frames") }}
                </span>
            </router-link>
            <router-link :to="{ name: 'price-matrices.index' }"
                         class="navbar-item"
                         v-auth.global-admin>
                <span>
                    {{ ucf$t("price-matrices.price-matrices") }}
                </span>
            </router-link>
            <router-link :to="{ name: 'parameters.index' }"
                         class="navbar-item"
                         v-auth.global-admin>
                <span>
                    {{ ucf$t("parameters.parameters") }}
                </span>
            </router-link>

            <div class="navbar-user"
                 @click="toggleMenu">
                <div :class="{'show' : showMenu}"
                     class="submenu">
                    <router-link :to="{ name: 'profile.show' }"
                                 class="menu-item">
                        {{ ucf$t("navbar.my_profile") }}
                    </router-link>
                    <router-link :to="{ name: 'auth.logout' }"
                                 class="menu-item">
                        {{ ucf$t("navbar.logout") }}
                    </router-link>
                </div>
                <img :src="user.imageUrl"
                     class="profile"
                     v-if="user.imageUrl">
                <div class="profile with-background"
                     v-else>
                    {{ initials }}
                </div>
                <p class="name">
                    {{ user.firstName }} {{ user.middleName }} {{ user.lastName }}
                </p>
                <img class="toggle"
                     src="/images/icons/dropdown-arrows.svg">
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: 'NavBar',
        data() {
            return {
                showMenu: false,
            };
        },
        methods: {
            /**
             * Toggles the menu.
             */
            toggleMenu() {
                this.showMenu = !this.showMenu;
            },
        },
        computed: {
            initials() {
                const { user } = this;

                if (this.empty(this.user.firstName)) {
                    return '';
                }

                return user.firstName.charAt(0) + user.lastName.charAt(0);
            },
            /**
             * Get the amount of unread notifications.
             */
            notificationAmount() {
                // return this.$store.getters.getNotificationAmount
                return 0;
            },
        },
    };
</script>
