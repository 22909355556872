<template>
    <base-overview ref="overview"
                   slug="/frames">
        <template v-slot:head>
            <h1>{{ $t("Frames") }}</h1>
            <router-link :to="{ name: 'frames.create.general', query: $route.query }"
                         class="btn primary">
                {{ $t("Create frame") }}
            </router-link>
        </template>
        <template v-slot:table-head>
            <div @click="$refs.overview.setOrderBy('frames.name')"
                 class="column pointer">
                {{ $t("Name") }}
            </div>
        </template>
        <template v-slot:table-row="{item}">
            <div class="column">
                <span>
                    {{ item.name }}
                </span>
            </div>
        </template>
        <template v-slot:no-more>
            {{ $t('These were all frames') }}
        </template>
    </base-overview>
</template>

<script>
    import BaseOverview from "../components/BaseOverview";

    export default {
        name: "FrameIndex",
        components: { BaseOverview },
        methods: {
            getRoute(frame) {
                return {
                    ...this.$route,
                    name: 'frames.edit',
                    params: {
                        frameId: frame.id,
                    },
                };
            },
        },
    };
</script>
