import OnboardingShow from "../../views/OnboardingShow";
import auth from '../middleware/_auth';

export default [
    {
        name: 'onboarding.show',
        path: '/onboarding',
        component: OnboardingShow,
        meta: {
            middleware: [auth],
        },
    },
]
