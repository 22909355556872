<template>
    <base-panel panel-class="modal project-reopen-modal small"
                @overlay-clicked="close"
                ref="panel">
        <div class="header">
            {{ ucf$t("projects.notification_header") }}
        </div>
        <div class="body">
            <label class="checkbox-container">
                <div class="checkbox"
                     :class="{'checked' : hasNotifications}" />
                {{ ucf$t("projects.has_notification") }}
                <input type="checkbox"
                       v-model="hasNotifications">
            </label>
            <button @click="confirm"
                    class="primary">
                {{ ucf$t("global.save") }}
            </button>
        </div>
    </base-panel>
</template>

<script>
    import moment from 'moment';
    import BasePanel from '../components/BasePanel';

    export default {
        name: 'ProjectNotifications',
        components: { BasePanel },
        data() {
            return {
                hasNotifications: this.project.hasNotifications,
            };
        },
        methods: {
            close() {
                this.$router.replace({
                    name: 'projects.show',
                    params: { projectId: this.project.id },
                    query: this.$route.query,
                });
            },
            confirm() {
                this.$http
                    .post(`/projects/${this.project.id}/notification`, {
                        hasNotifications: this.hasNotifications,
                    })
                    .then(() => {
                        this.$router.replace({
                            name: 'projects.show',
                            params: { projectId: this.project.id },
                            query: { t: moment().format('HHmmssSSS') },
                        });
                    })
                    .catch((error) => {
                        this.handleErrors(error.response);
                    });
            },
        },
        mounted() {
            this.$refs.panel.open();
        },
        beforeRouteLeave(to, from, next) {
            this.$refs.panel.close();
            return next();
        },
    };
</script>
