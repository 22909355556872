import { render, staticRenderFns } from "./FrameCreate.vue?vue&type=template&id=acb039dc&"
import script from "./FrameCreate.vue?vue&type=script&lang=js&"
export * from "./FrameCreate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports