<template>
    <auth-layout>
        <div class="login-content">
            <h1>
                {{ ucf$t('auth.login.header') }}<span class="dot">.</span>
            </h1>
            <form @submit.prevent="doLogin"
                  class="login-form">
                <input type="email"
                       name="email"
                       v-model="formData.email"
                       :placeholder="ucf$t('user.email')"
                       autofocus
                       class="big full-width">
                <input type="password"
                       name="password"
                       v-model="formData.password"
                       :placeholder="ucf$t('user.password')"
                       class="big full-width">
                <button type="submit"
                        class="primary big full-width with-margin">
                    {{ ucf$t('auth.login.button') }}
                </button>
                <router-link :to="{ name: 'password.forgot' }">
                    {{ ucf$t('auth.forgot_password') }}
                </router-link>
            </form>
            <p>
                {{ ucf$t('auth.no_account') }}
                <router-link :to="{ name: 'auth.register' }">
                    {{ ucf$t('auth.register_here') }}
                </router-link>
            </p>
        </div>
    </auth-layout>
</template>

<script>
    import AuthLayout from './Layouts/AuthLayout';

    export default {
        name: 'Login',
        components: { AuthLayout },
        data() {
            return {
                formData: {},
            };
        },
        methods: {
            doLogin() {
                this.login(this.formData);
            },
        },
    };
</script>
