<template>
    <transition name="fade">
        <div :key="keyBy"
             class="overview">
            <div class="head">
                <slot name="head" />
            </div>
            <base-tabs :tabs="tabs" />
            <div class="search-container"
                 v-if="searchEnabled">
                <div class="searchbar">
                    <img src="/images/icons/search-icon.svg"
                         title="search-icon">
                    <input :placeholder="$t('Search..')"
                           @input="$emit('input', $event.target.value)"
                           name="search">
                </div>
            </div>

            <div class="table">
                <div class="row header">
                    <slot name="table-head" />
                </div>
                <transition-group name="list">
                    <router-link :key="item.id"
                                 :to="$parent.getRoute(item)"
                                 class="row"
                                 v-for="item in items">
                        <slot :item="item"
                              name="table-row" />
                    </router-link>
                </transition-group>
                <infinite-loading :distance="400"
                                  @distance="1"
                                  @infinite="loadItems"
                                  ref="infiniteLoading"
                                  spinner="spiral">
                    <div class="no-results"
                         slot="no-more">
                        <slot name="no-more" />
                    </div>
                </infinite-loading>
            </div>
        </div>
    </transition>
</template>

<script>
    import BaseTabs from './BaseTabs';

    export default {
        name: 'BaseOverview',
        components: { BaseTabs },
        props: {
            slug: {
                required: true,
                type: String,
            },
            tabs: {
                required: false,
                type: Array,
                default() {
                    return [];
                },
            },
            searchEnabled: {
                required: false,
                type: Boolean,
                default() {
                    return true;
                },
            },
            keyBy: {
                required: false,
                type: String,
                default() {
                    return this.$route.query.t;
                },
            },
        },
        data() {
            return {
                search: '',
                page: 1,
                maxPage: 2,
                items: [],
                orderBy: {
                    field: 'created_at',
                    sequence: 'DESC',
                },
            };
        },
        methods: {
            setOrderBy(field) {
                const sequence = (field === this.orderBy.field && this.orderBy.sequence === 'DESC')
                    ? 'ASC'
                    : 'DESC';

                this.orderBy = {
                    field,
                    sequence,
                };

                this.page = 1;
                this.maxPage = 2;
                this.items = [];
                this.$refs.infiniteLoading.stateChanger.reset();
            },
            loadItems($state) {
                this.$http
                    .get(this.slug, {
                        page: this.page,
                        orderBy: this.orderBy.field,
                        sequence: this.orderBy.sequence,
                        search: this.search,
                    })
                    .then(({ data }) => {
                        if (data.data.length === 0) {
                            $state.complete();
                            return;
                        }

                        data.data.forEach((customer) => {
                            this.items.push(customer);
                        });

                        this.maxPage = data.meta.last_page;
                        $state.loaded();
                    });
                this.page++;
            },
        },
        watch: {
            search: {
                handler() {
                    this.items = [];
                    this.$refs.infiniteLoading.stateChanger.reset();
                    this.page = 1;
                    this.maxPage = 2;
                },
            },
        },
    };
</script>
