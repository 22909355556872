<template>
    <base-panel @overlay-clicked="close"
                class="sidebar-container"
                panel-class="sidebar price-matrix"
                ref="panel">
        <div class="header">
            <h1>
                {{ ucf$t('price-matrices.update') }}
            </h1>
        </div>
        <form class="body"
              ref="employeeForm"
              @submit.prevent="submit">
            <h2>
                {{ ucf$t('settings.general_data') }}
            </h2>
            <div class="form-group">
                <label for="name">
                    {{ ucf$t("price-matrices.name") }}
                </label>
                <input id="name"
                       name="name"
                       ref="name"
                       type="text"
                       v-model="formData.name">
            </div>

            <div class="form-group">
                <label>
                    {{ ucf$t("price-matrices.matrix") }}
                </label>
                <price-matrix-input :value="formData.matrix"
                                    @input="updatedPriceMatrix"
                                    ref="matrixField" />
            </div>

            <button class="primary">
                {{ ucf$t('price-matrices.update') }}
            </button>
        </form>
    </base-panel>
</template>

<script>
    import moment from 'moment';
    import BasePanel from '../components/BasePanel';
    import PriceMatrixInput from '../components/PriceMatrixInput';

    export default {
        name: 'PriceMatrixEdit',
        components: { PriceMatrixInput, BasePanel },
        data() {
            return {
                formData: {},
            };
        },
        methods: {
            updatedPriceMatrix(matrix) {
                this.formData.matrix = matrix;
            },
            submit() {
                this.$http.post(`/price-matrices/${this.priceMatrix.id}`, this.formData)
                    .then(() => {
                        this.$router.replace({
                            name: 'price-matrices.index',
                            params: this.$route.params,
                            query: {
                                ...this.$route.query,
                                t: moment().format('HHmmssSSS'),
                            },
                        });
                    });
            },
            close() {
                this.$router.replace({
                    name: 'price-matrices.index',
                    query: this.$route.query,
                });
            },
        },
        mounted() {
            this.formData = this.priceMatrix;
            this.$refs.matrixField.setMatrix(this.priceMatrix.matrix);

            this.$refs.panel.open();

            this.$refs.matrixField.$refs.panel.remount();
        },
        beforeRouteLeave(to, from, next) {
            this.$refs.panel.close();
            return next();
        },
    };
</script>
