<template>
    <transition name="fade">
        <canvas class="fbx-viewer" />
    </transition>
</template>

<script>
    import * as THREE from 'three/build/three.module';
    import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls';
    import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';

    export default {
        name: 'FbxViewer',
        props: {
            fbx: {
                required: true,
                type: String,
            },
            background: {
                required: false,
                type: Number,
                default() {
                    return 0x0D0D0D;
                },
            },
            highlightColor: {
                type: Number,
                required: false,
                default() {
                    return 0xEDEDED;
                },
            },
            onObjectHovered: {
                required: true,
                type: Function,
            },
            onObjectUnhovered: {
                required: true,
                type: Function,
            },
        },
        data() {
            return {
                controls: null,
                renderer: null,
                camera: null,
                scene: null,
                hovered: null,
                hoveredColor: null,
                mouse: new THREE.Vector2(),
            };
        },
        methods: {
            init() {
                this.renderer = new THREE.WebGLRenderer({ canvas: this.$el });
                this.createCamera();

                this.scene = new THREE.Scene();
                this.scene.background = new THREE.Color(this.background);

                this.loadObject();

                const light = new THREE.HemisphereLight(0xFFFFFF, 0x141414, 1);
                light.position.set(0, 5, -10);

                this.scene.add(light);

                this.createControls();
                requestAnimationFrame(this.animate);

                this.$el.addEventListener('dblclick', this.onClickCanvas, false);
                this.$el.addEventListener('mousemove', this.onMouseMoved, false);
            },
            createCamera() {
                this.camera = new THREE.PerspectiveCamera(70, 2, 1, 1000);
                this.camera.matrix.fromArray([
                    0.9415659237343355, 0.3196797624509377, 0.10610589400037858, 0,
                    -0.17815824861791418, 0.20532290226973537, 0.9623420100218687, 0,
                    0.2858552950562662, -0.9250120838577434, 0.250278634736268, 0,
                    44.15215923839444, -117.4076813095762, 72.78925143821928, 1,
                ]);
                this.camera.matrix.decompose(this.camera.position, this.camera.quaternion, this.camera.scale);
                this.camera.up.set(-0.09735616427274082, 0.951464004430552, 0.29195551981607204);
            },
            loadObject() {
                const loader = new FBXLoader();

                loader.load(
                    this.fbx,
                    (object) => {
                        object.scale.set(10, 10, 10);
                        this.$emit('object-loaded', object);
                        this.scene.add(object);
                        this.animate();
                    },
                    undefined,
                    (e) => {
                        throw e;
                    },
                );
            },
            createControls() {
                this.controls = new TrackballControls(this.camera, this.renderer.domElement);

                this.controls.target.set(-14.12664139624472, 70.7876082585877, 39.36248932771869);

                this.controls.rotateSpeed = 4.0;
                this.controls.zoomSpeed = 1.2;
                this.controls.panSpeed = 0.8;
                this.controls.staticMoving = true;
                this.controls.dynamicDampingFactor = 0.3;
                this.controls.keys = [65, 83, 68];

                this.controls.addEventListener('change', () => {
                    this.renderer.render(this.scene, this.camera);
                });
            },
            animate(time) {
                time *= 0.001; // seconds

                this.resizeCanvasToDisplaySize();
                this.highlightHovered();

                this.controls.update();
                this.renderer.render(this.scene, this.camera);
                requestAnimationFrame(this.animate);
            },
            resizeCanvasToDisplaySize() {
                const canvas = this.renderer.domElement;
                const width = canvas.clientWidth;
                const height = canvas.clientHeight;
                if (canvas.width !== width || canvas.height !== height) {
                    // you must pass false here or three.js sadly fights the browser
                    this.renderer.setSize(width, height, false);
                    this.camera.aspect = width / height;
                    this.camera.updateProjectionMatrix();
                    // set render target sizes here
                }
            },
            getMousePositionFrom(event) {
                let y = event.clientY - this.renderer.domElement.getBoundingClientRect().top;
                let x = event.clientX - this.renderer.domElement.getBoundingClientRect().left;

                y = -(y / this.renderer.domElement.clientHeight) * 2 + 1;
                x = (x / this.renderer.domElement.clientWidth) * 2 - 1;

                return new THREE.Vector2(x, y);
            },
            getObjectAt(mousePosition) {
                const raycaster = new THREE.Raycaster();
                raycaster.setFromCamera(mousePosition, this.camera);

                const intersected = raycaster.intersectObjects(this.scene.children, true);

                return intersected.length > 0 ? intersected[0] : null;
            },
            onClickCanvas(e) {
                const object = this.getObjectAt(this.getMousePositionFrom(e));

                if (object !== null) {
                    this.$emit('object-click', object);
                }
            },
            onMouseMoved(e) {
                this.mouse = this.getMousePositionFrom(e);
            },
            highlightHovered() {
                const object = this.getObjectAt(this.mouse);

                if (object !== null && (this.hovered === null || this.hovered.object.uuid !== object.object.uuid)) {
                    this.hovered = object;
                    this.onObjectHovered(object);
                }

                if (object === null && this.hovered !== null) {
                    this.hovered = null;
                    this.onObjectUnhovered(object, null);
                }
            },
        },
        mounted() {
            this.init();
        },
    };
</script>

<style lang="scss">
    .fbx-viewer {
        width            : 100%;
        height           : 100%;
        background-color : #0d0d0d;
        transition       : opacity .3s ease-out;
    }
</style>
