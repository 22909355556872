<template>
    <form class="discount-form"
          @change="submit"
          @submit.prevent="submit">
        <div class="form-group">
            <label for="discount">% {{ ucf$t("customers.discount") }}</label>
            <input id="discount"
                   name="discount"
                   type="text"
                   v-model="customer.discount"
                   @input="submit">
        </div>
    </form>
</template>

<script>
    import debounce from 'lodash/debounce';

    export default {
        name: 'CustomerPayment',
        methods: {
            submit: debounce(function _() {
                this.$http.post(`/customers/${this.customer.id}/discount`, { discount: this.customer.discount })
                    .catch((error) => {
                        this.handleErrors(error.response);
                    });
            }, 500),
        },
    };
</script>
