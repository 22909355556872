<template>
    <div class="contacts-container">
        <div class="search-container">
            <div class="searchbar">
                <img src="/images/icons/search-icon.svg"
                     title="search-icon">
                <input :placeholder="ucf$t('user.search')"
                       name="search"
                       v-model="search">
            </div>
        </div>
        <div class="table">
            <div class="row"
                 v-for="contact of contacts">
                <div class="column">
                    <img :src="contact.imageUrl"
                         class="profile"
                         v-if="contact.imageUrl">
                    <div class="profile with-background"
                         v-else>
                        {{ contact.firstName.charAt(0).toUpperCase() }}{{ contact.lastName.charAt(0).toUpperCase() }}
                    </div>
                </div>
                <div class="column contact-detail">
                    <strong>
                        {{ contact.fullName }}
                    </strong>
                    <span>
                        {{ contact.tagLine }}
                    </span>
                </div>
                <div class="column contact-person"
                     v-if="contact.id === project.contactPerson.id">
                    <strong>
                        {{ ucf$t('user.contact_person') }}
                    </strong>
                </div>
                <div class="column settings"
                     v-if="contact.id !== project.contactPerson.id && user.id === project.contactPerson.id">
                    <strong>
                        {{ ucf$t('global.settings') }}
                        <span class="arrow-down" />
                    </strong>
                    <div class="popover">
                        <div class="menu">
                            <router-link :to="{ name: 'projects.share.shared-with.transfer', params: { ...$route.params, userId: contact.id }, query: $route.query }"
                                         class="menu-item">
                                Eigenaar maken
                            </router-link>
                            <router-link :to="{ name: 'projects.share.shared-with.delete', params: { ...$route.params, userId: contact.id }, query: $route.query }"
                                         class="menu-item">
                                Verwijderen
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <router-view />
    </div>
</template>

<script>
    export default {
        name: 'ProjectShareSharedWith',
        data() {
            return {
                search: '',
                shownPopover: null,
            };
        },
        computed: {
            contacts: {
                get() {
                    return this.project.contacts
                        .filter(contact => contact.fullName.toLowerCase().includes(this.search.toLowerCase()));
                },
            },
        },
    };
</script>
