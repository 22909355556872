import moment from 'moment';
import { Authenticator } from '../../configuration/auth';
import { auth as authConfig } from '../../configuration/config';
import { empty } from '../../configuration/methods';
import Login from '../../views/Login';
import PasswordForgot from '../../views/PasswordForgot';
import PasswordReset from '../../views/PasswordReset';
import ProfileShow from '../../views/ProfileShow';
import Register from '../../views/Register';
import auth from '../middleware/_auth';
import guest from '../middleware/_guest';

export default [
    {
        name: 'profile.show',
        path: '/profile',
        component: ProfileShow,
        meta: {
            middleware: [auth],
        },
    },
    {
        name: 'auth.login',
        path: '/login',
        component: Login,
        meta: {
            middleware: [guest],
        },
    },
    {
        name: 'auth.register',
        path: '/register',
        component: Register,
        meta: {
            middleware: [guest],
        },
    },
    {
        name: 'auth.logout',
        path: '/logout',
        meta: {
            middleware: [auth],
        },
    },
    {
        name: 'auth.sso',
        path: '/sso',
        meta: {
            middleware: [
                ({ to, next }) => {
                    const { accessToken, refreshToken } = to.query;
                    const expiresAt = moment().add(3, 'hours').format('YYYY-MM-DD HH:mm:ss');

                    const Auth = new Authenticator(authConfig);

                    Auth._set('token', accessToken);
                    Auth._set('refreshToken', refreshToken);
                    Auth._set('expiresAt', expiresAt);

                    if (!empty(to.query.redirectTo)) {
                        next(to.query.redirectTo);
                        return;
                    }

                    next('/');
                },
            ],
        },
    },
    {
        name: 'password.forgot',
        path: '/password/forgot',
        component: PasswordForgot,
        meta: {
            middleware: [guest],
        },
    },
    {
        name: 'password.reset',
        path: '/password/reset/:token',
        component: PasswordReset,
        meta: {
            middleware: [guest],
        },
    },
]
