import kebabCase from 'lodash/kebabCase';
import camelCase from 'lodash/camelCase';
import pluralize from 'pluralize';
import Api from '../configuration/api'

export default class Service {
    constructor() {
        this.name = pluralize(kebabCase(this.constructor.name.replace('Service', '')));
    }

    getEntity() {
        return camelCase(this.constructor.name.replace('Service', ''));
    }

    async index() {
        const response = await Api.get(this.name);

        return response.data;
    }

    async find(id) {
        const response = await Api.get([this.name, id].join('/'));

        return response.data.data;
    }
}
