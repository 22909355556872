import { auth as authConfig } from '../../configuration/config';

export default function guest({ next, authenticator }) {
    authenticator.check()
        .then(() => {
            next(authConfig.options.redirects.success);
        })
        .catch(() => {
            next();
        });
}
