import { env } from 'simple-vue-env';
import { Flashes } from 'vue-flashes';
import i18n from '../trans';

export const auth = {
    client: {
        id: env('MIX_APP_CLIENT_ID'),
        secret: env('MIX_APP_CLIENT_SECRET'),
    },
    options: {
        roles: [],
        redirects: {
            login: {
                name: 'auth.login',
            },
            failed: {
                name: 'auth.login',
            },
            success: {
                name: 'projects.index',
            },
        },
        token: {
            header: 'Authorization',
            type: 'Bearer',
            url: '/oauth/token',
        },
        storageNames: {
            token: 'webo_bim_token',
            refreshToken: 'webo_bim_refresh_token',
            expiresAt: 'webo_bim_expires_at',
        },
        loginFailed() {
            Flashes.flash(
                i18n.t('auth.failed'),
                'error',
            );
        },
    },
};

export default {
    auth,
};
