import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './en';
import NL from './i18n/nl';
import nl from './nl';

Vue.use(VueI18n);

export default new VueI18n({
    locale: document.documentElement.lang || navigator.language.substr(0, 2) || 'nl',
    fallbackLocale: 'en',
    messages: { en, nl: { ...nl, ...NL } },
});
