<template>
    <div class="settings-container">
        <div class="header">
            <h1>{{ customer.name }}</h1>
            <!-- ToDo: Show delete modal-->
            <!--
            <div class="btn secondary"
                 v-on:click="showDeleteModal">
                {{ ucf$t("customers.delete") }}
            </div>
            -->
        </div>

        <div class="tabs">
            <router-link :to="{ name: 'customers.show.general', params: $route.params, query: $route.query }"
                         class="tab-item">
                {{ ucf$t('settings.general_data') }}
            </router-link>
            <router-link :to="{ name: 'customers.show.staff', params: $route.params, query: $route.query }"
                         class="tab-item">
                {{ ucf$t('settings.staff_members') }}
            </router-link>
            <router-link :to="{ name: 'customers.show.payment', params: $route.params, query: $route.query }"
                         class="tab-item">
                {{ ucf$t('customers.payment_details') }}
            </router-link>
        </div>

        <transition mode="out-in"
                    name="fade">
            <router-view />
        </transition>
    </div>
</template>

<script>
    export default {
        name: 'CustomerEdit',
        data() {
            return {
                contacts: [],
                countries: [],
            };
        },
        methods: {
            loadContacts() {
                this.$http.get('/customers/me/users').then((response) => {
                    this.contacts = response.data.data;
                });
            },
            init() {
                this.loadContacts();
                this.$http.get('/countries').then((response) => {
                    this.countries = response.data.data;
                });
            },
        },
        beforeMount() {
            this.init();
        },
    };
</script>
