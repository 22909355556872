import moment from 'moment';
import { env } from 'simple-vue-env';
import { Flashes } from 'vue-flashes';
import i18n from '../../trans';
import Api from '../api';
import { empty } from '../methods';

class Auth {
    constructor(configuration = { client: {}, options: {} }) {
        if (!empty(Auth._instance)) {
            return Auth._instance;
        }

        Auth._instance = this;

        this.options = {
            roles: [],
            storageNames: {
                token: 'token',
                refreshToken: 'refresh_token',
                expiresAt: 'expires_at',
            },
            token: {
                header: 'Authorization',
                type: 'Bearer',
                url: '/oauth/token',
            },
            redirects: {
                login: {
                    name: 'auth.login',
                },
                failed: {
                    name: 'auth.login',
                },
                success: {
                    name: 'home',
                },
            },
            afterLogin() {
                return Promise.resolve();
            },

            ...configuration.options,
        };
        this.client = {
            client_id: configuration.client.id || env('MIX_APP_CLIENT_ID'),
            client_secret: configuration.client.secret || env('MIX_APP_CLIENT_SECRET'),
            scope: '*',
        };

        this._bindRequestInterceptors();

        return this;
    }

    isAuthenticated() {
        if (!empty(this.getToken())) {
            const expiresAt = this.getExpiresAt();
            const now = moment();

            return expiresAt.isAfter(now);
        }

        return false;
    }

    getToken() {
        return this._get('token');
    }

    getRefreshToken() {
        return this._get('refreshToken');
    }

    getExpiresAt() {
        return moment(this._get('expiresAt'), 'YYYY-MM-DD HH:mm:ss');
    }

    check() {
        if (!this.isAuthenticated() || empty(this.getRefreshToken())) {
            return Promise.reject();
        }

        const expireWindow = this.getExpiresAt().subtract(2, 'hours');
        const now = moment();

        if (!now.isAfter(expireWindow)) {
            return this.options.afterLogin();
        }

        const data = {
            ...this.client,
            grant_type: 'refresh_token',
            refresh_token: this.getRefreshToken(),
        };

        return new Promise(async (resolve, reject) => {
            const response = await Api.post(this.options.token.url, data)
                .catch(() => {
                    this.logout();
                    reject();
                });

            this._setToken(response.data);

            this.options
                .afterLogin()
                .then(resolve)
                .catch(reject);
        });
    }

    login(user) {
        const formData = {
            ...this.client,
            grant_type: 'password',
            username: user.email,
            password: user.password,
        };

        return Api.post(this.options.token.url, formData)
            .then(({ data }) => {
                this._setToken(data);
                this.options.afterLogin();

                return null;
            });
    }

    logout() {
        this._remove('token');
        this._remove('refreshToken');
        this._remove('expiresAt');
    }

    _bindRequestInterceptors() {
        Api._axios.interceptors.request.use((config) => {
            if (this.isAuthenticated()) {
                config.headers.common[this.options.token.header] = [
                    this.options.token.type,
                    this.getToken(),
                ].join(' ');
            } else {
                delete config.headers.common[this.options.token.header];
            }

            return config;
        });

        Api._axios.interceptors.response.use(
            response => response,
            (error) => {
                if (typeof error.response === 'undefined') {
                    return Promise.reject(error);
                }

                const { status } = error.response;

                if (status === 403) {
                    Flashes.flash(i18n.t('auth.not_allowed'), 'error');
                }

                return Promise.reject(error);
            },
        );
    }

    _setToken({ access_token, expires_in, refresh_token }) {
        this._set('token', access_token);
        this._set('refreshToken', refresh_token);

        const expiresAt = moment().add(expires_in, 'seconds');

        this._set('expiresAt', expiresAt.format('YYYY-MM-DD HH:mm:ss'));
    }

    _get(what) {
        return window.localStorage.getItem(
            this.options.storageNames[what],
        );
    }

    _set(what, value) {
        return window.localStorage.setItem(
            this.options.storageNames[what],
            value,
        );
    }

    _remove(what) {
        return window.localStorage.removeItem(
            this.options.storageNames[what],
        );
    }
}

export const Authenticator = Auth;

export const VueAuthenticator = {
    install(vue, configuration) {
        const instance = new Auth(configuration);

        vue.mixin({
            computed: {
                $auth: {
                    get() {
                        return instance;
                    },
                },
            },
        });

        function authDirective() {
            return instance.isAuthenticated();
        }

        function roleDirective({ modifiers }, vnode) {
            const allRoles = vnode.context.roles;
            let invert = false;

            const roles = Object.keys(modifiers).map((r) => {
                let role = r;

                if (r.includes(':')) {
                    const split = r.split(':');

                    invert = split[1] === 'invert';
                    [role] = split;
                }

                return allRoles[role.replace(/-/g, '_').toUpperCase()];
            });
            const userRoles = vnode.context.user.roles || [];
            const intersection = userRoles.filter(role => roles.includes(role.id));

            return invert ? intersection.length <= 0 : intersection.length > 0;
        }

        vue.directive('auth', {
            bind(el, binding, vnode) {
                el.style.display = null;
                if (!authDirective() || !roleDirective(binding, vnode)) {
                    el.style.display = 'none';
                }
            },
        });

        vue.directive('auth-or', {
            bind(el, binding, vnode) {
                el.style.display = null;
                if (!authDirective()) {
                    el.style.display = 'none';
                    return;
                }

                if (!roleDirective(binding, vnode) && !binding.value) {
                    el.style.display = 'none';
                }
            },
        });

        vue.directive('auth-and', {
            bind(el, binding, vnode) {
                el.style.display = null;
                if (
                    (!authDirective() || !roleDirective(binding, vnode))
                    && !binding.value
                ) {
                    el.style.display = 'none';
                }
            },
        });

        vue.directive('guest', {
            bind(el) {
                if (instance.isAuthenticated()) {
                    el.style.display = 'none';
                }
            },
        });
    },
};
