<template>
    <auth-layout>
        <div class="login-content"
             key="1"
             v-if="$route.query.success !== true">
            <h1 class="small-margin">
                {{ ucf$t('auth.password.email.header') }}<span class="dot">.</span>
            </h1>
            <p class="sub-title">
                {{ ucf$t('auth.password.email.subtitle') }}
            </p>
            <form @submit.prevent="submit"
                  class="login-form"
                  method="post">
                <input :placeholder="ucf$t('user.email')"
                       autofocus
                       class="big full-width"
                       name="email"
                       type="email"
                       v-model="email">
                <button class="primary big full-width"
                        type="submit">
                    {{ ucf$t('auth.password.email.button') }}
                </button>
            </form>
        </div>
        <div class="login-content"
             key="2"
             v-else>
            <h1 class="small-margin">
                {{ ucf$t('auth.password.email.success') }}<span class="dot">.</span>
            </h1>
            <p class="sub-title">
                {{ ucf$t('auth.password.email.success_text') }}
            </p>
        </div>
    </auth-layout>
</template>

<script>
    import AuthLayout from './Layouts/AuthLayout';

    export default {
        name: 'PasswordForgot',
        components: { AuthLayout },
        data() {
            return {
                email: '',
            };
        },
        methods: {
            submit() {
                this.$http.post('/password/forgot', { email: this.email })
                    .then(() => {
                        this.$router.replace({
                            name: 'password.forgot',
                            query: { success: true },
                        });
                    })
                    .catch((error) => {
                        this.handleErrors(error.response);
                    });
            },
        },
        beforeRouteEnter(to, from, next) {
            if (from.name === null && Object.keys(to.query).includes('success')) {
                return next({ name: 'password.forgot' });
            }

            return next();
        },
    };
</script>
