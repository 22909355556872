import moment from 'moment';
import Vue from 'vue';
import VueFlashes from 'vue-flashes';
import VueInfiniteLoading from 'vue-infinite-loading';
import NavBar from '../components/NavBar';
import router from '../router';
import i18n from '../trans';
import Api from './api';
import { VueAuthenticator } from './auth';
import Base from './Base';
import config from './config';
import Helpers from './methods';
import {
 notificationTypes, roles, statuses, formulaTypes,
} from './variables';

Vue.mixin({
    components: { 'nav-bar': NavBar, 'infinite-loading': VueInfiniteLoading },
    data() {
        return {
 roles, statuses, notificationTypes, formulaTypes,
};
    },
    methods: Helpers,
    computed: {
        $http: {
            get() {
                return Api;
            },
        },
        user: {
            get() {
                return this.$user || JSON.parse(localStorage.getItem('webo_bim_user_data')) || { roles: [] };
            },
        },
    },
});

Vue.use(VueFlashes);
Vue.use(VueAuthenticator, config.auth);

moment.locale('nl');

export default {
    i18n,
    router,
    el: '#app',
    render: h => h(Base),
};
