import { Authenticator } from "../../configuration/auth";
import { auth as authConfig } from '../../configuration/config';
import { empty } from '../../configuration/methods/index';

function nextFactory(context, middleware, index) {
    const nextMiddleware = middleware[index];

    if (!nextMiddleware) return context.next;

    return (...parameters) => {
        context.next(...parameters);

        const next = nextFactory(context, middleware, index + 1);
        nextMiddleware({
            ...context,
            next,
        });
    };
}

export function executeMiddleware(to, from, next, router) {
    if (!empty(to.meta.middleware)) {
        let { middleware } = to.meta;

        if (!Array.isArray(middleware)) {
            middleware = [middleware];
        }

        const context = {
            to, from, router, next, redirect: next, authenticator: new Authenticator(authConfig),
        };

        return middleware[0]({
            ...context,
            next: nextFactory(context, middleware, 1),
        });
    }

    return next();
}
