<template>
    <div class="parameter-info-form">
        <div class="form-group">
            <label for="parameterName">
                {{ ucf$t('parameters.revit-name') }}
            </label>
            <input id="parameterName"
                   name="parameterName"
                   ref="parameterName"
                   type="text"
                   v-model="$parent.parameter.name">
        </div>
        <div class="form-group"
             v-if="!$parent.parameter.parameterIsSegmented && empty($parent.parameter.segments[0].optionType)">
            <label for="unit">
                {{ ucf$t('parameters.unit') }}
            </label>
            <input id="unit"
                   name="unit"
                   ref="unit"
                   type="text"
                   v-model="$parent.parameter.unit">
        </div>
        <div v-if="!$parent.parameter.parameterIsSegmented && empty($parent.parameter.unit)">
            <div class="segment-form"
                 v-for="segment in $parent.parameter.segments">
                <div class="form-group">
                    <label for="optionType">
                        {{ ucf$t('parameters.parameter-type') }}
                    </label>
                    <select id="optionType"
                            name="optionType"
                            v-model="segment.optionType">
                        <option :value="undefined">
                            {{ ucf$t('parameters.segments.option-types.placeholder') }}
                        </option>
                        <option :value="revitOption"
                                v-for="(revitOption) of $parent.revitOptions">
                            {{ ucf$t('parameters.segments.option-types.' + revitOption.translation) }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-group">
            <label for="parameterIsType">
                {{ ucf$t('parameters.instanceOrType') }}
            </label>
            <label class="checkbox">
                <input id="parameterIsType"
                       name="parameterIsType"
                       type="checkbox"
                       v-model="$parent.parameter.parameterIsType">
                <p>
                    {{ $parent.parameter.parameterIsType ? ucf$t('parameters.type') : ucf$t('parameters.instance') }}
                </p>
            </label>
        </div>
        <div class="form-group">
            <label for="parameterIsType">
                {{ ucf$t('parameters.segments-label') }}
            </label>
            <label class="checkbox">
                <input id="parameterIsSegmented"
                       name="parameterIsSegmented"
                       type="checkbox"
                       v-model="$parent.parameter.parameterIsSegmented">
                <p>
                    {{ $parent.parameter.parameterIsSegmented
                        ? ucf$t('parameters.segmented')
                        : ucf$t('parameters.not-segmented') }}
                </p>
            </label>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ParameterInfo',
    };
</script>
