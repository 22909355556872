import Vue from 'vue';
import Api from '../../configuration/api';
import { auth as authConfig } from '../../configuration/config';

async function loadProfile() {
    Vue.prototype.$user = JSON.parse(localStorage.getItem('webo_bim_user_data'));

    return Api.get('/users/current')
        .then((response) => {
            Vue.prototype.$user = response.data.data;
            localStorage.setItem(
                'webo_bim_user_data',
                JSON.stringify(Vue.prototype.$user),
            );
        })
        .catch((error) => {
            if (error.response.status === 401) {
                localStorage.removeItem('webo_bim_token');
                localStorage.removeItem('webo_bim_user_data');
                localStorage.removeItem('webo_bim_expires_at');
                localStorage.removeItem('webo_bim_refresh_token');
            }
            return error;
        });
}

export default function auth({ to, next, authenticator }) {
    if (to.name === 'auth.logout') {
        authenticator.logout();
        localStorage.removeItem('webo_bim_user_data');
        next(authConfig.options.redirects.login);
        return;
    }

    authenticator.check()
        .then(() => {
            loadProfile().then(next)
                .catch(() => {
                    next(authConfig.options.redirects.failed);
                });
        })
        .catch(() => {
            next(authConfig.options.redirects.failed);
        });
}
