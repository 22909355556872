<template>
    <auth-layout content-class="registration-content">
        <transition-group name="fade">
            <div class="login-content registration-content"
                 key="1"
                 v-if="$route.query.success !== true">
                <h1 class="registration-header">
                    {{ ucf$t('auth.register.header') }}<span class="dot">.</span>
                </h1>
                <p>
                    {{ ucf$t('auth.register.description') }}
                </p>
                <form @submit.prevent="submit"
                      class="register-form">
                    <div class="full-width registration-col">
                        <input :placeholder="ucf$t('user.first_name')"
                               autofocus
                               class="big field"
                               name="firstName"
                               type="text"
                               v-model="formData.firstName">
                        <input :placeholder="ucf$t('user.middle_name')"
                               class="big field"
                               name="middleName"
                               type="text"
                               v-model="formData.middleName">
                        <input :placeholder="ucf$t('user.last_name')"
                               class="big field"
                               name="lastName"
                               type="text"
                               v-model="formData.lastName">
                    </div>

                    <div class="registration-col">
                        <input :placeholder="ucf$t('user.email')"
                               class="big field half-field form-control"
                               name="email"
                               type="email"
                               v-model="formData.email">

                        <input :placeholder="ucf$t('user.phone')"
                               class="big field half-field form-control"
                               name="phone"
                               type="text"
                               v-model="formData.phone">
                    </div>

                    <div class="registration-col">
                        <input :placeholder="ucf$t('user.company_name')"
                               class="full-field field big form-control"
                               name="companyName"
                               type="text"
                               v-model="formData.companyName">
                    </div>

                    <div class="registration-col">
                        <select class="big full-field field form-control"
                                name="companyTypeId"
                                v-model="formData.companyTypeId">
                            <option :value="null"
                                    selected>
                                {{ ucf$t('user.company_type') }}
                            </option>
                            <option value="1">
                                Architectenbureau
                            </option>
                            <option value="2">
                                Aannemersbedrijf
                            </option>
                        </select>
                    </div>

                    <button class="primary big with-margin">
                        {{ ucf$t('auth.register.button') }}
                    </button>
                </form>
            </div>
            <div class="login-content"
                 key="2"
                 v-else>
                <h1 class="small-margin">
                    {{ ucf$t('auth.register.success') }}<span class="dot">.</span>
                </h1>
                <p class="sub-title"
                   v-html="ucf$t('auth.register.success_text', this.email)" />
            </div>
        </transition-group>
    </auth-layout>
</template>

<script>
    import AuthLayout from './Layouts/AuthLayout';

    export default {
        name: 'Register',
        components: { AuthLayout },
        data() {
            return {
                formData: { companyTypeId: null },
            };
        },
        methods: {
            submit() {
                this.$http.post('/register', this.formData)
                    .then(() => {
                        this.$router.replace({
                            ...this.$route,
                            query: {
                                ...this.$route.query,
                                success: true,
                            },
                        });
                    })
                    .catch((error) => {
                        this.handleErrors(error.response);
                    });
            },
        },
        mounted() {
            if (this.$route.query.token) {
                this.formData.projectToken = this.$route.query.token;
            }
        },
        beforeRouteEnter(to, from, next) {
            if (from.name === null && Object.keys(to.query).includes('success')) {
                return next({ name: 'auth.register' });
            }

            return next();
        },
    };
</script>
