<template>
    <div class="customer-container">
        <div class="header">
            <h1>
                {{ ucf$t("price-matrices.price-matrices") }}
            </h1>
            <router-link :to="{ name: 'price-matrices.create' }"
                         class="btn primary"
                         tag="button">
                {{ ucf$t("price-matrices.new") }}
            </router-link>
        </div>
        <div class="search-container">
            <div class="searchbar">
                <img src="/images/icons/search-icon.svg"
                     title="search-icon">
                <input :placeholder="ucf$t('global.search')"
                       :value="search"
                       @input="setSearch"
                       name="search">
            </div>
        </div>

        <div class="table">
            <div class="row header">
                <div @click="setOrderBy('name')"
                     class="column pointer">
                    {{ ucf$t("price-matrices.name") }}
                </div>
            </div>
            <transition-group name="list">
                <router-link :key="priceMatrix.id"
                             :to="{ name: 'price-matrices.edit', params: { priceMatrixId: priceMatrix.id }, query: $route.query }"
                             class="row"
                             v-for="priceMatrix in priceMatrices">
                    <div class="column">
                        <span>
                            {{ priceMatrix.name }}
                        </span>
                    </div>
                </router-link>
            </transition-group>
            <infinite-loading :distance="400"
                              @distance="1"
                              @infinite="infiniteHandler"
                              ref="infiniteLoading"
                              spinner="spiral">
                <div class="no-results"
                     slot="no-more">
                    {{ ucf$t("price-matrices.no_more") }}
                </div>
            </infinite-loading>
        </div>
        <router-view />
    </div>
</template>

<script>
    import debounce from 'lodash/debounce';

    export default {
        name: 'PriceMatrixIndex',
        data() {
            return {
                priceMatrices: [],
                search: this.$route.query.search || null,
                page: 1,
                maxPage: 2,
                orderBy: {
                    field: 'created_at',
                    sequence: 'DESC',
                },
            };
        },
        methods: {
            infiniteHandler($state) {
                let url = `/price-matrices?page=${this.page
                }&order_field=${this.orderBy.field
                }&order_sequence=${this.orderBy.sequence}`;

                if (this.$route.query.search) {
                    url = `${url}&search=${this.$route.query.search}`;
                }

                if (this.page <= this.maxPage) {
                    this.$http.get(url)
                        .then((response) => {
                            const { data } = response;
                            this.priceMatrices = [...this.priceMatrices, ...data.data];
                            this.maxPage = data.meta.last_page;
                            $state.loaded();
                        });

                    this.page = this.page + 1;
                } else {
                    $state.complete();
                }
            },
            setOrderBy(field) {
                if (field === this.orderBy.field) {
                    this.orderBy.sequence = this.orderBy.sequence === 'DESC' ? 'ASC' : 'DESC';
                } else {
                    this.orderBy.field = field;
                    this.orderBy.sequence = 'DESC';
                }

                this.$router.replace({
                    ...this.$route,
                    query: {
                        status: this.$route.query.status,
                        search: this.$route.query.search,
                        orderField: this.orderBy.field,
                        orderSequence: this.orderBy.sequence,
                    },
                });
            },
            setSearch: debounce(function _(e) {
                this.search = e.target.value;

                this.$router.replace({
                    ...this.$route,
                    query: {
                        ...this.$route.query,
                        search: this.search,
                        orderField: this.$route.query.orderField,
                        orderSequence: this.$route.query.orderSequence,
                    },
                });
            }, 500),
        },
        beforeRouteUpdate(to, from, next) {
            this.priceMatrices = [];
            this.page = 1;
            this.maxPage = 2;
            this.search = to.query.search;
            this.orderBy = {
                field: to.query.orderField,
                sequence: to.query.orderSequence,
            };

            setTimeout(() => {
                this.$refs.infiniteLoading.stateChanger.reset();
            }, 500);

            return next();
        },
        mounted() {
            this.search = this.$route.query.search || null;
            this.orderBy = {
                field: this.$route.query.orderField || 'created_at',
                sequence: this.$route.query.orderSequence || 'DESC',
            };
        },
    };
</script>
