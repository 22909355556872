<template>
    <div :key="$route.params.status"
         class="parameter-container">
        <div class="header">
            <h1>
                {{ ucf$t('parameters.create') }}{{ parameterName }}
            </h1>
            <button @click="destroy"
                    class="secondary"
                    type="button">
                {{ ucf$t('parameters.destroy') }}
            </button>
            <button @click="store"
                    class="primary"
                    type="button">
                {{ ucf$t('parameters.update') }}
            </button>
        </div>
        <div class="value-container">
            <div class="possible-value">
                <input :placeholder="ucf$t('parameters.possible-value')"
                       name="search"
                       v-model="possibleValue">
            </div>
            <div class="form-group"
                 v-if="segmentValues.length > 0">
                <p class="segmentation">
                    <span class="types">
                        <span v-for="(segment) in parameter.segments">
                            {{ getSegmentOptionType(segment) }}
                        </span>
                    </span>
                    <span class="values">
                        <span v-for="(value) in segmentValues">
                            {{ value }}{{ unit }}
                        </span>
                    </span>
                </p>
            </div>
        </div>
        <div class="tabs">
            <router-link :to="{ ...$route, name: 'parameters.edit.info' }"
                         class="tab-item">
                {{ ucf$t('parameters.general-info') }}
            </router-link>
            <router-link :to="{ ...$route, name: 'parameters.edit.segments' }"
                         class="tab-item"
                         v-if="parameter.parameterIsSegmented">
                {{ ucf$t('parameters.segments-label') }}
            </router-link>
            <router-link :to="{ ...$route, name: 'parameters.edit.form' }"
                         class="tab-item">
                {{ ucf$t('parameters.form') }}
            </router-link>
        </div>

        <router-view />
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        name: 'ParameterEdit',
        data() {
            return {
                possibleValue: '',
                revitOptions: {},
            };
        },
        methods: {
            addSegment(length = 1) {
                this.parameter.segments.push({
                    length,
                });
            },
            removeSegment(index) {
                this.parameter.segments.splice(index, 1);
            },
            getNameOfOptionValue(segment, segmentValue) {
                if (this.empty(segment.optionType) || this.empty(segmentValue)) {
                    return segmentValue;
                }

                const option = segment.optionType.data.find(opt => parseInt(segmentValue, 10) === opt.value) || {};

                return option.name || segmentValue;
            },
            getSegmentOptionType(segment) {
                if (this.empty(segment.optionType)) {
                    return '';
                }

                return this.ucf$t(`parameters.segments.option-types.${segment.optionType.translation}`);
            },
            store() {
                this.$http.post(`/parameters/${this.parameter.id}/edit`, this.parameter)
                    .then(() => {
                        this.$router.replace({ name: 'parameters.index' });
                    })
                    .catch((error) => {
                        this.handleErrors(error.response);
                    });
            },
            destroy() {
                this.$http.delete(`/parameters/${this.parameter.id}`, this.parameter)
                    .then(() => {
                        this.$router.replace({
                            name: 'parameters.index',
                            query: {
                                ...this.$route.query,
                                t: moment().format('HHmmssSSS'),
                            },
                        });
                    })
                    .catch((error) => {
                        this.handleErrors(error.response);
                    });
            },
        },
        watch: {
            'parameter.parameterIsSegmented': {
                handler(newValue, oldValue) {
                    if (newValue !== oldValue && newValue === false) {
                        this.parameter.segments = this.parameter.segments.splice(0, 1);
                        delete this.parameter.unit;

                        if (this.empty(this.parameter.segments)) {
                            this.parameter.segments = [];
                            this.addSegment(this.possibleValue.length);
                            return;
                        }

                        this.parameter.segments[0].length = this.possibleValue.length;
                    }
                    if (typeof newValue === typeof oldValue && typeof oldValue === 'undefined') {
                        this.parameter.segments = [];
                        this.addSegment(this.possibleValue.length);
                    }
                },
                immediate: true,
            },
            possibleValue: {
                handler(value) {
                    if (this.parameter.segments.length === 1) {
                        this.parameter.segments[0].length = value.length;
                    }
                },
            },
        },
        computed: {
            segmentValues: {
                get() {
                    const { possibleValue } = this;
                    const segmentValues = [];
                    let taken = 0;

                    this.parameter.segments.forEach((segment) => {
                        const length = parseInt(segment.length, 10);

                        if (length === 0) {
                            return;
                        }

                        const segmentValue = possibleValue.substr(taken, length);

                        if (segmentValue.length > 0) {
                            segmentValues.push(this.getNameOfOptionValue(segment, segmentValue));
                            taken += length;
                        }
                    });

                    return segmentValues;
                },
            },
            unit: {
                get() {
                    if (this.parameter.parameterIsSegmented) {
                        return '';
                    }

                    return this.parameter.unit;
                },
            },
            parameterName: {
                get() {
                    if (this.empty(this.parameter.name)) {
                        return '';
                    }

                    return `: ${this.parameter.name}`;
                },
            },
            canSave: {
                get() {
                    const { empty, parameter } = this;

                    if (empty(parameter.name)) {
                        return false;
                    }

                    if (parameter.parameterIsSegmented) {
                        return !empty(parameter.segments)
                            && parameter.segments.every(segment => !empty(segment.length) && !empty(segment.optionType));
                    }

                    return (empty(parameter.unit) && !empty(parameter.segments) && !empty(parameter.segments[0].optionType))
                        || (!empty(parameter.unit) && (empty(parameter.segments) || empty(parameter.segments[0].optionType)));
                },
            },
        },
        beforeMount() {
            this.$http.get('/parameters/revit-options')
                .then((response) => {
                    this.revitOptions = response.data;
                });
        },
    };
</script>
