<template>
    <transition name="fade">
        <div class="settings-container">
            <div class="header">
                <h1>{{ ucf$t('settings.title') }}</h1>
            </div>

            <div class="tabs">
                <router-link :to="{ name: 'settings.general' }"
                             class="tab-item">
                    {{ ucf$t('settings.general_data') }}
                </router-link>
                <router-link :to="{ name: 'settings.staff' }"
                             class="tab-item"
                             v-auth.ou-user-management>
                    {{ ucf$t('settings.staff_members') }}
                </router-link>
            </div>

            <transition mode="out-in"
                        name="fade">
                <router-view />
            </transition>
        </div>
    </transition>
</template>

<script>
    export default {
        name: 'SettingIndex',
        data() {
            return {
                customer: {
                    contactPerson: {
                        id: null,
                    },
                    address: {
                        country: {},
                    },
                    invoiceAddress: {
                        country: {},
                    },
                },
                contacts: [],
                countries: [],
                currentTab: 1,
            };
        },
        methods: {
            loadContacts() {
                this.$http.get('customers/me/users').then(response => this.contacts = response.data.data);
            },
            init() {
                this.loadContacts();
                this.$http.get('customers/me').then(response => this.customer = response.data.data);
                this.$http.get('countries').then(response => this.countries = response.data.data);
            },
        },
        beforeMount() {
            this.init();
        },
    };
</script>
