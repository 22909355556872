<template>
    <form @change="submit">
        <div class="company-details">
            <h3 class="company-details__heading">
                {{ ucf$t('settings.company_details') }}
            </h3>

            <label for="companyName">
                {{ ucf$t('settings.company_name') }}
            </label>
            <input id="companyName"
                   ref="name"
                   type="text"
                   v-model="value.name">

            <label for="contactPerson">
                {{ ucf$t('settings.contact_person') }}
            </label>
            <multiselect :close-on-select="true"
                         :multiple="false"
                         :options="contacts"
                         class="w-1/4 mr-4"
                         deselect-label=""
                         id="contactPerson"
                         label="fullName"
                         select-label=""
                         selected-label=""
                         track-by="id"
                         v-model="value.contactPerson"
                         @close="submit" />
            <div class="company-details__field-wrap">
                <div class="field">
                    <label for="kvkNumber">{{ $t('settings.kvk_number') }}</label>
                    <input id="kvkNumber"
                           ref="kvkNumber"
                           type="text"
                           v-model="value.kvkNumber">
                </div>

                <div class="field">
                    <label for="btwNumber">{{ $t('settings.btw_number') }}</label>
                    <input id="btwNumber"
                           ref="btwNumber"
                           type="text"
                           v-model="value.btwNumber">
                </div>
            </div>

            <file-input :value="logoImage"
                        @input="logoImageChanged"
                        class="company-details__upload-logo"
                        default="/">
                <template v-slot:default="{image,isLoading}">
                    <img :src="image"
                         alt="logo"
                         class="company-details__upload-logo__image"
                         v-if="!isLoading && image !== '/'">

                    <span class="company-details__upload-logo__image company-details__upload-logo__image--placeholder"
                          v-else />

                    <span class="company-details__upload-logo__text">
                        Bedrijfslogo wijzigen
                    </span>
                </template>
            </file-input>
        </div>

        <div class="company-details company-details--with-address">
            <div class="address">
                <h3 class="company-details__heading">
                    {{ ucf$t('settings.address_title') }}
                </h3>

                <div class="company-details__field-wrap">
                    <div class="field">
                        <label for="address.address">
                            {{ ucf$t('settings.address') }}
                        </label>
                        <input id="address.address"
                               ref="address.address"
                               type="text"
                               v-model="value.address.address">
                    </div>

                    <div class="field">
                        <label for="address.postalCode">
                            {{ ucf$t('settings.postal_code') }}
                        </label>
                        <input id="address.postalCode"
                               ref="address.postalCode"
                               type="text"
                               v-model="value.address.postalCode">
                    </div>
                </div>

                <div class="company-details__field-wrap">
                    <div class="field">
                        <label for="address.city">
                            {{ ucf$t('settings.city') }}
                        </label>
                        <input id="address.city"
                               ref="address.city"
                               type="text"
                               v-model="value.address.city">
                    </div>

                    <div class="field">
                        <label for="address.country">
                            {{ ucf$t('settings.country') }}
                        </label>
                        <multiselect :close-on-select="true"
                                     :multiple="false"
                                     :options="countries"
                                     class="w-1/4 mr-4"
                                     deselect-label=""
                                     id="address.country"
                                     label="name"
                                     select-label=""
                                     selected-label=""
                                     track-by="id"
                                     v-model="value.address.country"
                                     @close="submit" />
                    </div>
                </div>
            </div>

            <div class="address">
                <h3 class="company-details__heading">
                    {{ ucf$t('settings.invoice_address_title') }}
                </h3>

                <div class="company-details__field-wrap">
                    <div class="field">
                        <label for="invoiceAddress.address">
                            {{ ucf$t('settings.address') }}
                        </label>
                        <input id="invoiceAddress.address"
                               ref="invoiceAddress.address"
                               type="text"
                               v-model="value.invoiceAddress.address">
                    </div>

                    <div class="field">
                        <label for="invoiceAddress.postalCode">
                            {{ ucf$t('settings.postal_code') }}
                        </label>
                        <input id="invoiceAddress.postalCode"
                               ref="invoiceAddress.postalCode"
                               type="text"
                               v-model="value.invoiceAddress.postalCode">
                    </div>
                </div>

                <div class="company-details__field-wrap">
                    <div class="field">
                        <label for="invoiceAddress.city">
                            {{ ucf$t('settings.city') }}
                        </label>
                        <input id="invoiceAddress.city"
                               ref="invoiceAddress.city"
                               type="text"
                               v-model="value.invoiceAddress.city">
                    </div>

                    <div class="field">
                        <label for="invoiceAddress.country">
                            {{ ucf$t('settings.country') }}
                        </label>
                        <multiselect :close-on-select="true"
                                     :multiple="false"
                                     :options="countries"
                                     class="w-1/4 mr-4"
                                     deselect-label=""
                                     id="invoiceAddress.country"
                                     label="name"
                                     select-label=""
                                     selected-label=""
                                     track-by="id"
                                     v-model="value.invoiceAddress.country"
                                     @close="submit" />
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
    import Multiselect from 'vue-multiselect';
    import FileInput from '../components/FileInput';

    export default {
        name: 'SettingGeneral',
        components: { FileInput, Multiselect },
        data() {
            return {
                logoImage: null,
                value: null,
            };
        },
        methods: {
            setImage(image) {
                if (!image) {
                    return;
                }

                if (image.startsWith('/') || image.startsWith('http')) {
                    this.logoImage = image;
                    return;
                }

                this.logoImage = `/${image}`;
            },
            logoImageChanged(logo) {
                if (logo.constructor !== File) {
                    return;
                }

                const formData = new FormData();
                formData.append('logo', logo);

                this.logoImage = logo;

                this.$http.post(`customers/${this.$parent.customer.id}/update-logo`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                    .catch((error) => {
                        this.handleErrors(error.response);
                    });
            },
            submit() {
                // Todo: Check where `data` (should come from)
                this.$http.post(`/customers/${this.$parent.customer.id}/edit`, data)
                    .catch((error) => {
                        this.handleErrors(error.response);
                    });
            },
        },
        watch: {
            '$parent.customer': {
                handler(customer) {
                    this.setImage(customer.logo);
                    this.value = this.deepClone(customer);
                },
                deep: true,
                immediate: true,
            },
        },
        computed: {
            contacts: {
                get() {
                    return this.$parent.contacts;
                },
            },
            countries: {
                get() {
                    return this.$parent.countries;
                },
            },
        },
    };
</script>
