<template>
    <form @submit.prevent="submit"
          class="body with-tabs">
        <div class="form-group">
            <label for="emails">
                {{ ucf$t("user.email") }}
            </label>
            <input id="emails"
                   placeholder="voorbeeld@gmail.com,extra@voorbeeld.nl"
                   ref="emails"
                   type="text"
                   v-model="emails">
        </div>
        <p>
            {{ ucf$t("projects.suggestions") }}
        </p>
        <div class="suggestions">
            <div class="row"
                 v-for="user in suggestions"
                 @click="addEmail(user.email)">
                <img :src="user.imageUrl"
                     class="profile"
                     v-if="user.imageUrl">
                <div class="profile with-background"
                     v-else>
                    {{ user.firstName.charAt(0).toUpperCase() }}{{ user.lastName.charAt(0).toUpperCase() }}
                </div>
                <div class="detail">
                    <p class="name">
                        {{ user.fullName }}
                    </p>
                    <p class="customer">
                        {{ user.customer }}
                    </p>
                </div>
            </div>
        </div>
        <button class="primary">
            {{ ucf$t("projects.send_invitation") }}
        </button>
    </form>
</template>

<script>
    import moment from 'moment';

    export default {
        name: 'ProjectShareInvite',
        data() {
            return {
                emails: '',
                suggestions: [],
            };
        },
        methods: {
            submit() {
                this.$http.post(`/projects/${this.$route.params.project}/share`, this.formData)
                    .then(() => {
                        this.$router.replace({
                            name: 'projects.show',
                            params: this.$route.params,
                            query: { t: moment().format('HHmmssSSS') },
                        });
                    })
                    .catch((error) => {
                        this.handleErrors(error.response);
                    });
            },
        },
        computed: {
            formData: {
                get() {
                    let emailString = this.emails;

                    if (emailString.slice(-1) === ',') {
                        emailString = emailString.substr(0, emailString.length - 1);
                    }

                    return {
                        emails: emailString.replace(' ', '').split(',').filter(str => str.length > 0),
                    };
                },
            },
        },
        beforeMount() {
            this.$http.get(`/projects/${this.$route.params.project}/suggestions`)
                .then((response) => {
                    this.suggestions = response.data.data;
                });
        },
    };
</script>
