<template>
    <div :key="$route.params.status"
         class="parameter-container">
        <div class="header">
            <h1>
                {{ $t('Edit frame') }}
            </h1>
            <button @click="store"
                    class="primary"
                    type="button">
                {{ $t('Update') }}
            </button>
        </div>
        <div class="tabs">
            <router-link :to="{ ...$route, name: 'frames.edit.general' }"
                         active-class=""
                         class="tab-item"
                         exact-active-class="active">
                {{ $t('General') }}
            </router-link>
            <router-link :to="{ ...$route, name: 'frames.edit.parameters' }"
                         active-class=""
                         class="tab-item"
                         exact-active-class="active">
                {{ $t('Parameters') }}
            </router-link>
            <router-link :to="{ ...$route, name: 'frames.edit.formulas' }"
                         active-class=""
                         class="tab-item"
                         exact-active-class="active">
                {{ $t('Formulas') }}
            </router-link>
            <router-link :to="{ ...$route, name: 'frames.edit.models' }"
                         active-class=""
                         class="tab-item"
                         exact-active-class="active">
                {{ $t('Models') }}
            </router-link>
        </div>

        <router-view />
    </div>
</template>

<script>
    import moment from 'moment';
    import ParameterService from "../services/ParameterService";

    export default {
        name: 'FrameEdit',
        data() {
            return {
                revitOptions: {},
                parameters: [],
            };
        },
        methods: {
            appendToFormData(formData, key, value) {
                if (value instanceof File) {
                    formData.append(key, value);
                    return;
                }

                if (Array.isArray(value)) {
                    value.forEach((val, index) => {
                        this.appendToFormData(formData, `${key}[${index}]`, val);
                    });
                    return;
                }

                if (typeof value === 'object') {
                    Object.entries(value).forEach(([k, val]) => {
                        this.appendToFormData(formData, `${key}[${k}]`, val);
                    });
                    return;
                }

                formData.append(key, value);
            },
            store() {
                const formData = new FormData();

                Object.entries(this.frame).forEach(([key, value]) => {
                    this.appendToFormData(formData, key, value);
                });

                this.$http.post(`/frames/${this.frame.id}/edit`, formData)
                    .then(() => {
                        this.$router.replace({
                            name: 'frames.index',
                            query: {
                                ...this.$route.query,
                                t: moment().format('HHmmssSSS'),
                            },
                        });
                    })
                    .catch((error) => {
                        this.handleErrors(error.response);
                    });
            },
        },
        beforeMount() {
            (new ParameterService).index().then((data) => {
                this.parameters = data.data;
            });
        },
    };
</script>
