<template>
    <base-panel panel-class="modal project-cancel-modal small"
                @overlay-clicked="close"
                ref="panel">
        <div class="header">
            {{ ucf$t("projects.cancel") }}
        </div>
        <div class="body">
            <p>
                {{ ucf$t("projects.cancel_description") }}
            </p>
            <button @click="confirm"
                    class="primary">
                {{ ucf$t("global.yes") }}
            </button>
            <router-link :to="{ name: 'projects.show', params: { projectId: project.id } }"
                         class="secondary"
                         tag="button">
                {{ ucf$t("global.no") }}
            </router-link>
        </div>
    </base-panel>
</template>

<script>
    import moment from 'moment';
    import BasePanel from '../components/BasePanel';

    export default {
        name: 'ProjectCancel',
        components: { BasePanel },
        methods: {
            close() {
                this.$router.replace({
                    name: 'projects.show',
                    params: { projectId: this.project.id },
                    query: this.$route.query,
                });
            },
            confirm() {
                this.$http
                    .post(`/projects/${this.project.id}/status`, {
                        projectStatus: this.statuses.CANCELLED,
                    })
                    .then(() => {
                        this.$router.replace({
                            name: 'projects.show',
                            params: { projectId: this.project.id },
                            query: { t: moment().format('HHmmssSSS') },
                        });
                    })
                    .catch((error) => {
                        this.handleErrors(error.response);
                    });
            },
        },
        mounted() {
            this.$refs.panel.open();
        },
        beforeRouteLeave(to, from, next) {
            this.$refs.panel.close();
            return next();
        },
    };
</script>
