export default function boundBy(service, vueConfig = {}) {
    const entity = service.getEntity();

    return {
        ...vueConfig,
        data() {
            return {
                ...(vueConfig.data()),
                [entity]: {},
            };
        },
        methods: {
            ...vueConfig.methods,
            _setEntity(data) {
                this.$set(this, entity, data);
            },
        },
        beforeMount() {
            service.find(this.$route.params[`${entity}Id`])
                .then((data) => {
                    this._setEntity(data);
                });
        },
    };
}
