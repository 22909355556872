<template>
    <transition name="fade">
        <div :key="$route.params.status"
             class="project-container">
            <div class="header">
                <h1>{{ ucf$t("projects.projects") }}</h1>
                <router-link :to="{ name: 'projects.create' }"
                             class="btn primary"
                             v-auth.global-admin:invert>
                    {{ ucf$t("projects.new") }}
                </router-link>
            </div>
            <div class="tabs">
                <router-link :to="{ name: $route.name, params: { status: 1 } }"
                             class="tab-item">
                    {{ ucf$t("projects.open") }}
                </router-link>
                <router-link :to="{ name: $route.name, params: { status: 2 } }"
                             class="tab-item">
                    {{ ucf$t("projects.agreed") }}
                </router-link>
                <router-link :to="{ name: $route.name, params: { status: 3 } }"
                             class="tab-item">
                    {{ ucf$t("projects.finished") }}
                </router-link>
                <router-link :to="{ name: $route.name, params: { status: 4 } }"
                             class="tab-item">
                    {{ ucf$t("projects.canceled") }}
                </router-link>
            </div>
            <div class="search-container">
                <div class="searchbar">
                    <img src="/images/icons/search-icon.svg"
                         title="search-icon">
                    <input :placeholder="ucf$t('global.search')"
                           :value="search"
                           name="search"
                           @input="setSearch">
                </div>
            </div>

            <div class="table">
                <div class="row header">
                    <div class="column pointer"
                         @click="setOrderBy('name')">
                        {{ ucf$t("projects.project") }}
                    </div>
                    <div class="column pointer"
                         @click="setOrderBy('created_at')">
                        {{ ucf$t("projects.create_date") }}
                    </div>
                    <div class="column">
                        {{ ucf$t("projects.contact") }}
                    </div>
                    <div class="column right">
                        {{ ucf$t("projects.offer_price") }}
                    </div>
                </div>
                <transition-group name="list">
                    <router-link :key="project.id"
                                 :to="{ name: 'projects.show', params: { projectId: project.id } }"
                                 class="row"
                                 v-for="project in projects">
                        <div class="column">
                            <span>
                                {{ project.name }}
                            </span>
                        </div>
                        <div class="column">
                            <span>
                                {{ ucfirst(dateFormat(project.createdAt, $t("global.hour"))) }}
                            </span>
                        </div>
                        <div class="column">
                            <span>
                                {{ project.contactPerson.fullName }}
                            </span>
                            <span class="font-light"
                                  v-auth.global-admin>
                                {{ project.customer.name }}
                            </span>
                            <div class="circle">
                                <img src="/images/icons/email-icon.svg"
                                     title="email">
                                <div class="popover">
                                    {{ project.contactPerson.email }}
                                </div>
                            </div>
                            <div class="circle">
                                <img src="/images/icons/call-icon.svg"
                                     title="call">
                                <div class="popover">
                                    {{ project.contactPerson.phoneNumber }}
                                </div>
                            </div>
                        </div>
                        <div class="column right">
                            <span>
                                € {{ numberFormat(9800000) }}
                            </span>
                        </div>
                    </router-link>
                </transition-group>
                <infinite-loading :distance="400"
                                  @infinite="infiniteHandler"
                                  ref="infiniteLoading"
                                  spinner="spiral">
                    <div class="no-results"
                         slot="no-more">
                        {{ ucf$t("projects.no_more") }}
                    </div>
                </infinite-loading>
                <transition mode="out-in"
                            name="fade">
                    <router-view :key="$route.fullPath" />
                </transition>
            </div>
        </div>
    </transition>
</template>

<script>
    import debounce from 'lodash/debounce';

    export default {
        name: 'ProjectIndex',
        data() {
            return {
                projects: [],
                search: this.$route.query.search || null,
                page: 1,
                maxPage: 2,
                orderBy: {
                    field: 'created_at',
                    sequence: 'DESC',
                },
            };
        },
        methods: {
            infiniteHandler($state) {
                let url = `/projects?status=${this.$route.params.status
                }&page=${this.page
                }&order_field=${this.orderBy.field
                }&order_sequence=${this.orderBy.sequence}`;

                if (this.$route.query.search) {
                    url = `${url}&search=${this.$route.query.search}`;
                }

                if (this.page <= this.maxPage) {
                    this.$http.get(url)
                        .then((response) => {
                            const { data } = response;
                            this.projects = [...this.projects, ...data.data];
                            this.maxPage = data.meta.last_page;
                            $state.loaded();
                        });

                    this.page = this.page + 1;
                } else {
                    $state.complete();
                }
            },
            setOrderBy(field) {
                if (field === this.orderBy.field) {
                    this.orderBy.sequence = this.orderBy.sequence === 'DESC' ? 'ASC' : 'DESC';
                } else {
                    this.orderBy.field = field;
                    this.orderBy.sequence = 'DESC';
                }

                this.$router.replace({
                    ...this.$route,
                    query: {
                        status: this.$route.query.status,
                        search: this.$route.query.search,
                        orderField: this.orderBy.field,
                        orderSequence: this.orderBy.sequence,
                    },
                });
            },
            setSearch: debounce(function _(e) {
                this.search = e.target.value;

                this.$router.replace({
                    ...this.$route,
                    query: {
                        ...this.$route.query,
                        search: this.search,
                        orderField: this.$route.query.orderField,
                        orderSequence: this.$route.query.orderSequence,
                    },
                });
            }, 500),
        },
        beforeRouteUpdate(to, from, next) {
            this.projects = [];
            this.page = 1;
            this.maxPage = 2;
            this.search = null;
            this.orderBy = {
                field: 'created_at',
                sequence: 'DESC',
            };

            if (to.params.status === from.params.status) {
                this.search = to.query.search;
                this.orderBy = {
                    field: to.query.orderField,
                    sequence: to.query.orderSequence,
                };
            }

            setTimeout(() => {
                this.$refs.infiniteLoading.stateChanger.reset();
            }, 500);

            return next();
        },
        mounted() {
            this.search = this.$route.query.search || null;
        },
    };
</script>
