<template>
    <div class="login-container">
        <slot />
        <div class="login-side">
            <div class="logo">
                <svg height="40px"
                     version="1.1"
                     viewBox="0 0 127 40"
                     width="127px"
                     xmlns="http://www.w3.org/2000/svg">
                    <g fill="none"
                       fill-rule="evenodd"
                       id="Authentication"
                       stroke="none"
                       stroke-width="1">
                        <g id="Activate-account"
                           transform="translate(-1729.000000, -72.000000)">
                            <g id="right"
                               transform="translate(1068.000000, 0.000000)">
                                <g id="logo"
                                   transform="translate(661.000000, 72.000000)">
                                    <polygon fill="#FFFFFF"
                                             id="Fill-1"
                                             points="0 6 61 6 61 0 0 0" />
                                    <path d="M26.479215,33.6946333 L18.7741698,33.7064492 L18.7741698,11 L13.225137,11 L13.225137,33.7149173 L5.54885948,33.7265362 L5.54885948,11 L0,11 L0,36.0174455 C0,38.2134335 1.57164829,40 3.50445162,40 L28.4957217,40 C30.4283517,40 32,38.2134335 32,36.0174455 L32,11 L26.4507939,11 L26.479215,33.6946333"
                                          fill="#000000"
                                          id="Fill-3" />
                                    <path d="M61,17.2268578 L61,11 L37.4780871,11 C36.6627274,11 36,11.7485766 36,12.6686898 L36,38.3305323 C36,39.2514234 36.6627274,40 37.4780871,40 L61,40 L61,33.7717808 L41.5152632,33.8046489 L41.5068219,28.5152135 L60.9314361,28.5152135 L60.9314361,22.4435555 L41.4964857,22.4435555 L41.4880444,17.2268578 L61,17.2268578"
                                          fill="#000000"
                                          id="Fill-4" />
                                    <path d="M123.534659,11 L102.465341,11 C100.554108,11 99,12.7643769 99,14.9328956 L99,36.0671044 C99,38.2358176 100.554108,40 102.465341,40 L123.534659,40 C125.445892,40 127,38.2358176 127,36.0671044 L127,14.9328956 C127,12.7643769 125.445892,11 123.534659,11 M121.540999,33.7735311 L104.486248,33.8042599 L104.458487,17.2264689 L121.513752,17.1957401 L121.540999,33.7735311"
                                          fill="#FFFFFF"
                                          id="Fill-5" />
                                    <path d="M94,21.2218519 L94,14.9326484 C94,12.7641587 92.4459296,11 90.5347434,11 L67.3722567,11 C66.6155277,11 66,11.6985843 66,12.5574229 L66,38.4419937 C66,39.3012212 66.6155277,40 67.3722567,40 L90.5347434,40 C92.4459296,40 94,38.2356468 94,36.0665737 L94,29.7771757 C94,28.0548309 93.3020478,26.5145225 92.2108227,25.4997083 C93.3020478,24.4843106 94,22.9441967 94,21.2218519 Z M71.3772996,33.8346824 L88.5957968,33.8346824 L88.5957968,28.5196462 L71.3772996,28.5196462 L71.3772996,33.8346824 Z M71.3772996,22.4480696 L88.5957968,22.4480696 L88.5957968,17.1336168 L71.3772996,17.1336168 L71.3772996,22.4480696 Z"
                                          fill="#FFFFFF"
                                          id="Fill-6" />
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
            <div class="login-images">
                <img :title="$t('auth.image')"
                     class="lines"
                     src="/images/lines.svg">
                <picture>
                    <source srcset="/images/auth-image.webp"
                            type="image/webp">
                    <img :title="$t('auth.image')"
                         class="image"
                         src="/images/auth-image.png">
                </picture>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AuthLayout',
    };
</script>
