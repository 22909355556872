import authRoutes from './_auth';
import customers from './_customers';
import frames from './_frames';
import notifications from './_notifications';
import onboarding from './_onboarding';
import parameters from './_parameters';
import priceMatrices from './_price-matrices';
import projects from './_projects';
import settings from './_settings';

export default [
    // Default redirect
    {
        path: '/',
        redirect: '/projects',
    },

    ...authRoutes,
    ...customers,
    ...frames,
    ...notifications,
    ...onboarding,
    ...parameters,
    ...priceMatrices,
    ...projects,
    ...settings,
];
