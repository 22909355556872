import uniqueId from 'lodash/uniqueId';

export default class Formula {
    constructor(object) {
        this.uuid = uniqueId();
        this.objectUuid = object.ID;
        this.formula = '';
        this.widthFormula = '';
        this.heightFormula = '';
        this.price = '';
    }
}
