<template>
    <base-panel panel-class="modal project-modal"
                @overlay-clicked="close"
                ref="panel">
        <div class="header">
            {{ ucf$t("projects.edit") }}
            <router-link :to="{ name: 'projects.show', param: { project: project.id }, query: $route.query }">
                <img src="/images/icons/close-icon.svg">
            </router-link>
        </div>
        <div class="body">
            <div class="form-group">
                <label for="name">
                    {{ ucf$t("projects.name") }}
                </label>
                <input id="name"
                       ref="name"
                       type="text"
                       v-model="project.name">
            </div>
            <div class="form-group">
                <label for="description">
                    {{ ucf$t("projects.description") }}
                </label>
                <textarea id="description"
                          ref="description"
                          v-model="project.description" />
            </div>
            <div class="form-group"
                 v-if="project.contacts">
                <label for="contactPerson">
                    {{ ucf$t("projects.contact") }}
                </label>
                <multiselect :close-on-select="true"
                             :multiple="false"
                             :options="project.contacts"
                             class="w-1/4 mr-4"
                             deselect-label=""
                             id="contactPerson"
                             label="fullName"
                             select-label=""
                             selected-label=""
                             track-by="id"
                             v-model="project.contactPerson" />
            </div>
            <button class="primary"
                    @click="submitForm">
                {{ ucf$t("global.save_data") }}
            </button>
        </div>
    </base-panel>
</template>

<script>
    import moment from 'moment';
    import Multiselect from 'vue-multiselect';
    import BasePanel from '../components/BasePanel';

    export default {
        name: 'ProjectEdit',
        components: { BasePanel, Multiselect },
        methods: {
            close() {
                this.$router.replace({
                    name: 'projects.show',
                    params: { projectId: this.project.id },
                    query: this.$route.query,
                });
            },
            submitForm() {
                const vm = this;
                const data = {
                    name: this.project.name,
                    description: this.project.description,
                    contactPerson: this.project.contactPerson.id ? this.project.contactPerson.id : null,
                };

                this.$http.post(`projects/${this.project.id}`, data)
                    .then(() => {
                        vm.$router.replace({
                            name: 'projects.show',
                            params: { projectId: this.project.id },
                            query: { t: moment().format('HHmmssSSS') },
                        });
                    })
                    .catch((error) => {
                        vm.handleErrors(error.response);
                    });
            },
        },
        mounted() {
            this.$refs.panel.open();
        },
        beforeRouteLeave(to, from, next) {
            this.$refs.panel.close();
            return next();
        },
    };
</script>
