import { roles } from "../../configuration/variables";
import NotificationCreate from "../../views/NotificationCreate";
import NotificationIndex from "../../views/NotificationIndex";
import auth from '../middleware/_auth';
import role from '../middleware/_role';

export default [
    {
        name: 'notifications.index',
        path: '/notifications',
        component: NotificationIndex,
        children: [
            {
                name: 'notifications.create',
                path: 'create',
                component: NotificationCreate,
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
        ],
        meta: {
            middleware: [auth],
        },
    },
];
