<template>
    <base-panel @overlay-clicked="close"
                panel-class="modal project-modal"
                ref="panel">
        <div class="header with-tabs">
            <img src="/images/icons/close-icon.svg"
                 @click="close">
            {{ ucf$t("projects.share_settings") }}
            <div class="tabs">
                <router-link :to="{ name: 'projects.share.invite', params: $route.params, query: $route.query }"
                             class="tab-item">
                    {{ ucf$t("projects.share") }}
                </router-link>
                <router-link :to="{ name: 'projects.share.shared-with', params: $route.params, query: $route.query }"
                             class="tab-item">
                    {{ ucf$t("projects.shared_with") }}
                </router-link>
            </div>
        </div>
        <router-view />
    </base-panel>
</template>

<script>
    import BasePanel from '../components/BasePanel';

    export default {
        name: 'ProjectShare',
        components: { BasePanel },
        methods: {
            close() {
                this.$router.replace({
                    name: 'projects.show',
                    params: this.$route.params,
                    query: this.$route.query,
                });
            },
        },
        mounted() {
            this.$refs.panel.open();
        },
        beforeRouteLeave(to, from, next) {
            this.$refs.panel.close();
            return next();
        },
    };
</script>
