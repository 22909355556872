import { roles } from "../../configuration/variables";
import PriceMatrixService from "../../services/PriceMatrixService";
import auth from '../middleware/_auth';
import role from '../middleware/_role';
import PriceMatrixCreate from '../../views/PriceMatrixCreate';
import PriceMatrixEdit from '../../views/PriceMatrixEdit';
import PriceMatrixIndex from '../../views/PriceMatrixIndex';
import ModelBoundView from "../utils/ModelBoundView";

export default [
    {
        name: 'price-matrices.index',
        path: '/price-matrices',
        component: PriceMatrixIndex,
        children: [
            {
                name: 'price-matrices.create',
                path: 'create',
                component: PriceMatrixCreate,
            },
            {
                name: 'price-matrices.edit',
                path: ':priceMatrixId/edit',
                component: ModelBoundView(new PriceMatrixService, PriceMatrixEdit),
            },
        ],
        meta: {
            middleware: [auth, role(roles.GLOBAL_ADMIN)],
        },
    },
];
