import { roles } from "../../configuration/variables";
import FrameService from "../../services/FrameService";
import auth from '../middleware/_auth';
import role from '../middleware/_role';
import FrameCreate from '../../views/FrameCreate';
import FrameEdit from "../../views/FrameEdit";
import FrameFormulas from '../../views/FrameFormulas';
import FrameGeneral from '../../views/FrameGeneral';
import FrameIndex from "../../views/FrameIndex";
import FrameModels from "../../views/FrameModels";
import FrameParameters from '../../views/FrameParameters';
import ModelBoundView from "../utils/ModelBoundView";

export default [
    {
        name: 'frames.index',
        path: '/frames',
        component: FrameIndex,
        meta: {
            middleware: [auth, role(roles.GLOBAL_ADMIN)],
        },
    },
    {
        name: 'frames.create',
        path: '/frames/create',
        component: FrameCreate,
        children: [
            {
                name: 'frames.create.general',
                path: 'general',
                component: FrameGeneral,
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
            {
                name: 'frames.create.parameters',
                path: 'parameters',
                component: FrameParameters,
                meta: {
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN),
                        ({ from, next }) => {
                            if (from.name === null) {
                                return next({ name: 'frames.create.general' });
                            }

                            return next();
                        },
                    ],
                },
            },
            {
                name: 'frames.create.formulas',
                path: 'formulas',
                component: FrameFormulas,
                meta: {
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN),
                        ({ from, next }) => {
                            if (from.name === null) {
                                return next({ name: 'frames.create.general' });
                            }

                            return next();
                        }
                    ],
                },
            },
            {
                name: 'frames.create.models',
                path: 'modules',
                component: FrameModels,
                meta: {
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN),
                        ({ from, next }) => {
                            if (from.name === null) {
                                return next({ name: 'frames.create.general' });
                            }

                            return next();
                        },
                    ],
                },
            },
        ],
        meta: {
            middleware: [
                auth,
                role(roles.GLOBAL_ADMIN),
            ],
        },
    },
    {
        name: 'frames.edit',
        path: '/frames/:frameId',
        component: ModelBoundView(new FrameService, FrameEdit),
        children: [
            {
                name: 'frames.edit.general',
                path: 'general',
                component: FrameGeneral,
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
            {
                name: 'frames.edit.parameters',
                path: 'parameters',
                component: FrameParameters,
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
            {
                name: 'frames.edit.formulas',
                path: 'formulas',
                component: FrameFormulas,
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
            {
                name: 'frames.edit.models',
                path: 'modules',
                component: FrameModels,
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
        ],
        meta: {
            middleware: [
                auth,
                role(roles.GLOBAL_ADMIN),
                ({ to, next }) => {
                    return next({ ...to, name: 'frames.edit.general' });
                },
            ],
        },
    },
]
