<template>
    <div class="frame-modules">
        <pre>
// Todo: Discuss the way we are going to implement, which conditions etc further.
        </pre>
    </div>
</template>

<script>
    export default {
        name: "FrameModels",
    };
</script>
